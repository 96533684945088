import React from 'react';
import './App.css';
import LoginButton from './login';
import LogoutButton from './logout';
import Profile from './profile';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>One Line Per Day</h1>
        <p>Coming Soon...</p>
        <p>Micro journal one line per day.</p>
        <LoginButton />
        <LogoutButton />
        <Profile />
      </header>
    </div>
  );
}

export default App;